<template>
    <div>
        <section class="ftco-section ">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12 heading-section text-center ftco-animated">                        
                        <h2 class="mb-4">{{topic}}</h2>
                    </div>
                </div>

                <div class="row">
                    <ChoirMinistryListComp v-bind:HideLockConent="true"></ChoirMinistryListComp>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ChoirMinistryListComp from "../components/ChoirMinistryListComp.vue";
import EventBus from "../Event-Bus.vue";

export default {
    components: {
        ChoirMinistryListComp
    },
    data(){
        return{
            topic:"Choir Ministry"
        }
    },
    mounted(){
        let tVue = this;
        EventBus.$on("ChoirMinistryLabel", (_topic) => {
            tVue.topic = _topic;
        });
    }
}
</script>

<style scoped>

</style>